export const THEMES = {
  LIGHT: 'LIGHT',
  DARK: 'DARK',
  NATURE: 'NATURE'
};

export const PROGRAM_TYPES = {
  COMMAND: "COMMAND",
  LINK: "LINK",
};

export const SCREEN_TYPES = {
  REGULAR: "REGULAR",
}
